import React, { useState, useEffect, useCallback } from 'react'
import { Routes, Route, useParams, useNavigate, Link, Navigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import Alert from '@mui/material/Alert'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import KeyIcon from '@mui/icons-material/Key'
import FormControlLabel from '@mui/material/FormControlLabel'
import { default as MetaSourceIcon } from '@mui/icons-material/DocumentScanner'

import { Breadcrumbs } from './settings/Breadcrumbs'
import { useTranslation } from './services/i18n'
import { useSnackbar } from './components/Snackbars'
import { request } from './state/api'
import { RoleAutoSuggestList } from './components/RoleAutoSuggestList'
import { AutoSuggestList } from './components/AutoSuggestList'
import { ResourceRole } from '../../api/app/roles'
import { useInstance } from './state/auth/currentInstance'
import { FormButtons } from './components/FormButtons'
import { FormTabs } from './components/FormTabs'
import { Form, FormContent, Label, Field, Heading } from './components/Form'
import { FullPageDataTableContainer } from './components/DataTable'
import { Source } from '../../api/app/sources'

// Some driver ids and driver properties come with wonderful constructs based on colons and dots. As it happens,
// i18next uses these for namespaces and nested objects by default so this would clash. Don't want to change the
// defaults of i18next here, so we simply replace those characters with dashes.
const safeKey = (k: string) => k.replaceAll(':', '-').replaceAll('.', '-')
const driverKey = (sourceType: string, k: string) => `source.${safeKey(sourceType || '')}${k}`

type TDriverOption = {
  property: string
  default_value: string
  is_encrypted: boolean
  description: string
  documentation_url: string
  is_advanced: boolean
  data_type: string
  allowed_values?: string[]
  group: string
}

type TDriverConfig = {
  [key: string]: any
}

export const ConnectedSystemsForm = () => (
  <Routes>
    <Route path="/" element={<Navigate to="driver-config" replace />} />
    <Route path=":tabId" element={<Content />} />
  </Routes>
)

export const Content = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const instanceId = useInstance(instance => instance.gsr_inst)
  const { connectedSystemId: recordId, tabId: currentTab } = useParams()
  const { showSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [label, setLabel] = useState<string>('')
  const [user, setUser] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [url, setUrl] = useState<string>('')
  const [endpoint, setEndpoint] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [comments, setComments] = useState<string>('')
  const [tags, setTags] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)

  const [roles, setRoles] = useState<ResourceRole[]>([])
  const [driverId, setDriverId] = useState<string>('')
  const [sourceType, setSourceType] = useState<string>('')
  const [config, setConfig] = useState<TDriverConfig>({})
  const [options, setOptions] = useState<TDriverOption[]>([])
  const [metaDriverId, setMetaDriverId] = useState('')
  const [metaSourceType, setMetaSourceType] = useState('')
  const [metaConfig, setMetaConfig] = useState<TDriverConfig>({})
  const [metaOptions, setMetaOptions] = useState<TDriverOption[]>([])
  const [hasMetaConnection, setHasMetaConnection] = useState(false)

  const getConfigOptions = useCallback(
    async (currentSourceType: string, configSetter: any, optionsSetter: any) => {
      if (!currentSourceType) {
        configSetter({})
        optionsSetter([])
        return
      }

      setIsLoading(true)
      optionsSetter((await request('/drivers/' + currentSourceType)).data as TDriverOption[])
      setIsLoading(false)
    },
    []
  )

  useEffect(() => {
    getConfigOptions(sourceType, setConfig, setOptions)
  }, [sourceType, getConfigOptions])
  useEffect(() => {
    getConfigOptions(metaSourceType, setMetaConfig, setMetaOptions)
  }, [metaSourceType, getConfigOptions])

  const fillDefaults = useCallback((currentConfig: any, currentOptions: any, configSetter: any) => {
    // Fill the config object with defaults where appropriate.
    let prefilledConfig = { ...currentConfig }
    let update = false
    currentOptions.forEach((option: any) => {
      if (typeof currentConfig[option.property] === 'undefined' && option.default_value) {
        update = true
        prefilledConfig[option.property] =
          option.data_type === 'bool' ? option.default_value === 'TRUE' : option.default_value
      }
    })

    update && configSetter(prefilledConfig)
  }, [])

  useEffect(() => {
    fillDefaults(config, options, setConfig)
  }, [fillDefaults, options, config, recordId])
  useEffect(() => {
    fillDefaults(metaConfig, metaOptions, setMetaConfig)
  }, [fillDefaults, metaOptions, metaConfig, recordId])

  const load = useCallback(async () => {
    setIsLoading(true)
    const response = (await request('/sources/' + recordId)).data
    let source = response as Source

    setLabel(source.name || '')
    setEndpoint(source.endpoint || '')
    setUrl(source.url || '')
    setDescription(source.description || '')
    setComments(source.comments || '')
    setUser(source.user || '')
    setPassword(source.password || '')
    setTags(source.tags || '')
    setIsActive(source.is_active || false)

    setDriverId(source.driver || '')
    setSourceType(source.source_type || '')
    setConfig(source.config || {})
    setIsLoading(false)
    setRoles(source.assignedRoles || [])

    setMetaSourceType(source.$meta_source.source_type || '')
    setMetaDriverId(source.$meta_source.driver || '')
    setMetaConfig(source.$meta_source.config || {})
    setHasMetaConnection(source.$meta_source_configured)
  }, [recordId])

  useEffect(() => {
    recordId && load()
  }, [recordId, load])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsSaving(true)

    const jsonBody: Source = {
      label,
      roles,
      config,
      user,
      password,
      endpoint,
      url,
      description,
      comments,
      tags,
      is_active: isActive,
      driver: driverId,
      source_type: sourceType,
      // The body here is missing stuff from Source type. I don't want to deal with that right now
      // maybe someday we can introduce a better type for the meta source :)
      // @ts-ignore
      $meta_source: {
        driver: metaDriverId,
        source_type: metaSourceType,
        config: metaConfig,
      },
    }

    try {
      if (!recordId) {
        await request(`/instances/${instanceId}/sources`, { method: 'post', jsonBody })
      } else {
        await request(`/sources/${recordId}`, { method: 'put', jsonBody })
      }

      showSnackbar({ message: t('save-success'), severity: 'success' })
      navigate('../../')
    } catch (e) {
      showSnackbar({ message: t('save-error'), severity: 'error' })
    }

    setIsSaving(false)

    return false
  }

  let breadcrumbTitle = t('navigation.update-resource')

  if (!recordId) {
    breadcrumbTitle = t('add-new-source')
  }

  return (
    <FullPageDataTableContainer>
      <Breadcrumbs>
        <Link to="../../">
          <Typography color="text.secondary">{t('navigation.sources')}</Typography>
        </Link>
        <Typography color="text.primary">{breadcrumbTitle}</Typography>
      </Breadcrumbs>
      <Form onSubmit={e => handleSubmit(e)}>
        <FormTabs
          tabs={[
            { id: 'driver-config', label: t('driver-config'), icon: <TextSnippetIcon /> },
            { id: 'meta-source', label: t('meta-source'), icon: <MetaSourceIcon /> },
            { id: 'acl', label: t('acl'), icon: <KeyIcon /> },
          ]}
        />

        <FormContent hidden={currentTab !== 'driver-config'}>
          <Label htmlFor="name">{t('name')}</Label>
          <Field>
            <TextField
              autoFocus
              id="name"
              type="text"
              hiddenLabel
              value={label}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              disabled={Boolean(recordId)}
              onChange={e => setLabel(e.target.value)}
            />
          </Field>

          <Label htmlFor="endpoint">{t('source-endpoint')}</Label>
          <Field>
            <TextField
              id="endpoint"
              type="text"
              hiddenLabel
              value={endpoint}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              onChange={e => setEndpoint(e.target.value)}
            />
          </Field>

          <Label htmlFor="user">{t('source-user')}</Label>
          <Field>
            <TextField
              id="user"
              type="text"
              hiddenLabel
              value={user}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              onChange={e => setUser(e.target.value)}
            />
          </Field>

          <Label htmlFor="password">{t('source-password')}</Label>
          <Field>
            <TextField
              id="password"
              type="password"
              hiddenLabel
              value={password}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
            />
          </Field>

          <Label htmlFor="description">{t('source-description')}</Label>
          <Field>
            <TextField
              id="description"
              type="text"
              hiddenLabel
              value={description}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              onChange={e => setDescription(e.target.value)}
            />
          </Field>

          <Label htmlFor="comments">{t('source-comments')}</Label>
          <Field>
            <TextField
              id="comments"
              type="text"
              hiddenLabel
              value={comments}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              onChange={e => setComments(e.target.value)}
            />
          </Field>

          <Label htmlFor="tags">{t('source-tags')}</Label>
          <Field>
            <TextField
              id="tags"
              type="text"
              hiddenLabel
              value={tags}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              onChange={e => setTags(e.target.value)}
            />
          </Field>

          <Label htmlFor="url">{t('source-url')}</Label>
          <Field>
            <TextField
              id="name"
              type="text"
              hiddenLabel
              value={url}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              onChange={e => setUrl(e.target.value)}
            />
          </Field>

          <Label></Label>
          <Field sx={{ mt: 5, mb: 5 }}>
            <FormControlLabel
              control={
                <Checkbox checked={isActive} onChange={e => setIsActive(e.target.checked)} />
              }
              label={t('source-is-active')}
            />
          </Field>

          <DriverSelector
            driverId={driverId}
            sourceType={sourceType}
            setDriverId={setDriverId}
            setSourceType={setSourceType}
          />

          <ConfigOptions
            driverId={driverId}
            sourceType={sourceType}
            configOptions={options}
            config={config}
            setConfig={setConfig}
          />
        </FormContent>

        <FormContent hidden={currentTab !== 'meta-source'}>
          {(!hasMetaConnection || !recordId) && (
            <div style={{ width: '100%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasMetaConnection}
                    onChange={e => setHasMetaConnection(e.target.checked)}
                  />
                }
                label={t('use-meta-source')}
              />
            </div>
          )}

          {hasMetaConnection && (
            <>
              <DriverSelector
                driverId={metaDriverId}
                setDriverId={setMetaDriverId}
                sourceType={metaSourceType}
                setSourceType={setMetaSourceType}
              />

              <ConfigOptions
                driverId={metaDriverId}
                sourceType={metaSourceType}
                configOptions={metaOptions}
                config={metaConfig}
                setConfig={setMetaConfig}
              />
            </>
          )}
        </FormContent>

        <FormContent hidden={currentTab !== 'acl'}>
          <Field md={12}>
            <RoleAutoSuggestList roles={roles} setRoles={setRoles} />
          </Field>
        </FormContent>

        {currentTab === 'acl' && (
          <>
            <Alert severity="info">{t('permissions-hint-delay')}</Alert>
          </>
        )}

        <FormButtons isSaving={isSaving} canSave={!isLoading} />
      </Form>
    </FullPageDataTableContainer>
  )
}

const DriverSelector = (props: {
  driverId?: string
  sourceType?: string
  setDriverId: (driverId: string) => void
  setSourceType: (sourceType: string) => void
}) => {
  const { t } = useTranslation()

  let field = (
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              onClick={e => {
                props.setDriverId('')
                props.setSourceType('')
              }}>
              Reset
            </Button>
          </InputAdornment>
        ),
      }}
      value={t(driverKey(props.sourceType || '', '.title'))}
      margin="dense"
      hiddenLabel
      variant="standard"
      type="text"
      disabled
    />
  )

  if (!props.driverId) {
    field = (
      <AutoSuggestList<{ driver: string; source_type: string }>
        items={[]}
        endpoint="/drivers"
        getItemKey={template => template && template.source_type}
        getItemLabel={template => template && t(driverKey(template.source_type || '', '.title'))}
        onChange={selection => {
          props.setDriverId(selection[0].driver)
          props.setSourceType(selection[0].source_type)
        }}
        inputPlaceholder={t('select-driver')}
      />
    )
  }

  return (
    <React.Fragment>
      <Label htmlFor="driver">{t('driver')}</Label>
      <Field>{field}</Field>
    </React.Fragment>
  )
}

const ConfigOptions = (props: {
  sourceType?: string
  driverId?: string
  configOptions: TDriverOption[]
  config: TDriverConfig
  setConfig: (config: TDriverConfig) => void
}) => {
  const { t } = useTranslation()

  const handleChange = (property: string, value: any) => {
    props.setConfig({ ...props.config, [property]: value })
  }

  if (!props.configOptions.length) {
    return <React.Fragment>{t('select-driver-first')}.</React.Fragment>
  }

  let currentGroup = ''

  const renderOption = (option: TDriverOption) => {
    if (option.data_type === 'string' && option.allowed_values?.length) {
      // This is actually a select field.
      option.data_type = 'select'
    }

    const translatedLabel = t(
      driverKey(props.sourceType || '', `.properties.${safeKey(option.property)}`)
    )

    let inputElement

    switch (option.data_type) {
      case 'string':
      case 'int':
        inputElement = (
          <TextField
            type={option.is_encrypted ? 'password' : 'text'}
            hiddenLabel
            margin="dense"
            variant="standard"
            value={props.config[option.property] || ''}
            onChange={e => handleChange(option.property, e.target.value)}
          />
        )
        break
      case 'bool':
        inputElement = (
          <Field>
            <Checkbox
              checked={props.config[option.property] || false}
              onChange={e => handleChange(option.property, e.target.checked)}
            />
          </Field>
        )
        break
      case 'select':
        inputElement = (
          <TextField
            select
            hiddenLabel
            id={option.property}
            margin="dense"
            variant="standard"
            value={props.config[option.property] || ''}
            onChange={e => handleChange(option.property, e.target.value)}>
            {option.allowed_values?.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        )
        break
      default:
        inputElement = <span>Unsupported Type: {option.data_type}</span>
    }

    let groupHeader = <React.Fragment key={option.property} />

    if (option.group !== currentGroup) {
      groupHeader = (
        <Heading variant="subtitle2" mt={!!currentGroup ? 4 : 1} mb={1}>
          {t(driverKey(props.sourceType || '', `.groups.${safeKey(option.group)}`))}
        </Heading>
      )
      currentGroup = option.group
    }

    return (
      <React.Fragment key={option.property}>
        {groupHeader}
        <Label htmlFor={option.property}>{translatedLabel}</Label>
        <Field>{inputElement}</Field>
      </React.Fragment>
    )
  }

  return <React.Fragment>{props.configOptions.map(option => renderOption(option))}</React.Fragment>
}
