import React, { useState, useEffect, useRef } from 'react'
import './Layout.css'
import { Link } from 'react-router-dom'
import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import BrokenImage from '@mui/icons-material/BrokenImage'
import debounce from 'lodash/debounce'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import { AuthMenu, Menu, SettingsMenu } from './Menu'
import { InstanceSelector } from './InstanceSelector'
import { useClient } from '../../state/auth/currentClient'
import { LoadRequestButton } from './LoadRequestButton'
import { useConfig } from '../../state/config'
import logo_white from '../../assets/logo-v2-white.json'
import { ReactComponent as YellowBalls } from '../../assets/yellow-balls.svg'
import logoMark from '../../assets/flowbi-logo-mark.webp'

const RootContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
}))

const MainAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}))

const contentPadding = 10

const Content = styled(Paper)(({ theme }) => ({
  padding: 0 + contentPadding,
  paddingTop: 50 + contentPadding,
  paddingLeft: 73 + contentPadding,
  width: '100%',
  minHeight: '100%',
  background: 'none',
}))

const LogoIcon = styled(BrokenImage)(({ theme }) => ({
  verticalAlign: 'middle',
  position: 'relative',
  top: -2,
}))

const AppTitle = styled(Typography)(({ theme }) => ({
  minWidth: '200px',
  '& a': {
    color: theme.palette.background.paper,
    textDecoration: 'none',
  },
}))

const CustomLogoContainer = ({ url }: { url: string }) => {
  return (
    <div
      style={{
        height: '35px',
        minWidth: '500px',
        marginLeft: '22px',
        display: 'flex',
        gap: 20,
      }}>
      <img src={logoMark} alt="logo" />
      <img src={url} alt="Custom logo" />
    </div>
  )
}

const CenteredToolbar = styled(Toolbar)(() => ({
  justifyContent: 'space-between',
}))

const MaxWidthContainer = styled('div')(() => ({
  //maxWidth: '1500px',
}))

const SecondaryMenuContainer = styled('div')(() => ({
  //marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = (props: LayoutProps) => {
  const logo = useClient(client => client.logo)
  const logoRef = useRef<LottieRefCurrentProps>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      logoRef.current?.goToAndPlay(0)
    }, 30000) // 30 seconds

    return () => clearInterval(interval) // cleanup on component unmount
  }, [])
  return (
    <RootContainer>
      <CssBaseline />
      <MainAppBar
        elevation={0}
        position="fixed"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <AppTitle variant="h5" noWrap>
          <Link to="/">
            {logo ? (
              <CustomLogoContainer url={logo} />
            ) : (
              <Lottie
                animationData={logo_white}
                loop={false}
                autoplay={true}
                lottieRef={logoRef}
                style={{ maxWidth: '180px', display: 'flex', alignItems: 'center' }}
              />
            )}
          </Link>
        </AppTitle>
        <CenteredToolbar
          variant="dense"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <InstanceSelector />
          <SecondaryMenuContainer>
            <LoadRequestButton />
            <SettingsMenu />
            <AuthMenu />
          </SecondaryMenuContainer>
        </CenteredToolbar>
      </MainAppBar>

      <NaviContainer />

      <Content elevation={0} square style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, height: '100%', overflow: 'hidden' }}>{props.children}</div>
        <Footer />
      </Content>
    </RootContainer>
  )
}

const Footer = () => {
  const clientName = useClient(client => client.name)
  const version = useConfig(({ version }) => version)
  const revision = useConfig(({ revision }) => revision)
  const buildTime = useConfig(({ buildTime }) => buildTime)

  return (
    <div
      style={{
        color: '#b3b3b3',
        paddingTop: '10px',
        fontSize: '.8em',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <span>{clientName}</span>
      <span title={revision + ' @ ' + buildTime}>{version}</span>
      <span style={{ opacity: 0 }}>{clientName}</span>
    </div>
  )
}

const ColorChangingBalls = styled(YellowBalls)(({ theme }) => ({
  position: 'absolute',
  bottom: 35,
  width: '85%',
  height: 'auto',
  color: theme.palette.secondary.main,
  '& path, & circle, & rect': {
    fill: 'currentColor',
  },
  zIndex: -1,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme }) => ({
  '& .MuiDrawer-paper': {
    paddingTop: '50px',
    background: `linear-gradient(${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 100%)`,
    '&:hover ul a:not(.active)': {
      opacity: 1,
    },
    '&:hover ul a:hover': {
      opacity: 1,
    },
    ul: {
      a: {
        '&.active': {
          backgroundColor: 'rgba(0,0,0,.3)',
        },
        paddingLeft: '1.3em',
        transition: 'all ease-in-out .2s',
        borderStyle: 'solid',
        borderWidth: '0 0 0 .2em',
        borderColor: 'transparent',
        //opacity: .6,
        color: theme.palette.primary.contrastText,
        '> div:first-of-type': {
          color: theme.palette.primary.contrastText,
        },
      },
    },

    overflowX: `hidden`,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  },
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'fixed',
  zIndex: 3,
  overflowX: `hidden`,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
}))

const NaviContainer = () => {
  const [isNavOpened, setIsNavOpened] = useState(false)

  const openNavi = debounce(() => setIsNavOpened(true), 2000)
  const closeNavi = debounce(() => setIsNavOpened(false), 100)

  const handleEnter = () => {
    openNavi()
    closeNavi.cancel()
  }

  const handleLeave = () => {
    closeNavi()
    openNavi.cancel()
  }

  return (
    <Drawer
      variant="permanent"
      open={isNavOpened}
      onClick={() => handleLeave()}
      onMouseEnter={() => handleEnter()}
      onMouseLeave={() => handleLeave()}>
      <Menu />
      <ColorChangingBalls />
    </Drawer>
  )
}
