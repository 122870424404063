import { useEffect, useRef, useState } from 'react'
import {
  Stimulsoft,
  StiDesigner,
  StiDesignerOptions,
  StiOptions,
} from 'stimulsoft-dashboards-js/Scripts/stimulsoft.blockly.editor'
import { useConfig } from '../state/config'
import { useUser } from '../state/auth/currentUser'
import { useClient } from '../state/auth/currentClient'
import { useInstance } from '../state/auth/currentInstance'
import { request } from '../state/api'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useTranslation } from '../services/i18n'
import { Field, FormContent, Label } from '../components/Form'
import { getVariables, sanitizeAndFormatGroup, StimulsoftLicenseKey } from './ReportDashboard'
import { Report } from './ReportDashboard'
import { useNavigate } from 'react-router-dom'
import { handleEnterKeyDown } from '../utils/reportUtils'
import FontLoader from './FontLoader'

type Params = {
  id: string | undefined
  updateSidebar: () => void
}

interface Column {
  Name: string
  Type: string
  Alias: string
  NameInSource: string
}

interface DataSource {
  Name: string
  Alias: string
  Ident: string
  SqlCommand: string
  NameInSource: string
  CommandTimeout: number
  Columns?: Record<string, Column>
}

export const DesignerDashboard = (params: Params) => {
  const gsr_client = useClient(state => state.gsr_client)
  const gsr_inst = useInstance(state => state.gsr_inst)

  const argsRef = useRef<Stimulsoft.Designer.SaveReportArgs | null>(null)
  const dataSourceRef = useRef<string[]>([])

  useEffect(() => {
    dataSourceRef.current = []
  }, [params.id])

  const emptyReport = {
    id: '',
    name: '',
    group: '',
    isDashboard: true,
    template: {},
    gsr_client: gsr_client,
    gsr_inst: gsr_inst,
  }

  // Note id is set by the backend. We store it later for save and saveAs functionality
  const [report, setReport] = useState<Report>(emptyReport)
  const reportRef = useRef<Report | null>(emptyReport)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [receivedReport, setReceivedReport] = useState(false)
  const [height, setHeight] = useState(window.innerHeight)

  const [saveDialogIsOpen, setSaveDialogIsOpen] = useState(false)
  const [saveAsDialogIsOpen, setSaveAsDialogIsOpen] = useState(false)

  const { t } = useTranslation()

  const user = useUser()
  const client = useClient()
  const instance = useInstance()
  const reportsUrl = useConfig(store => store.reportsUrl)

  // Update URL with the id of newly saved dashboard
  const updateURL = (data: any) => {
    if (data && data.id) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('id', data.id)
      navigate({
        pathname: window.location.pathname,
        search: searchParams.toString(),
      })
    }
  }

  useEffect(() => {
    if (params.id === 'new') {
      // Modify page title for New Dashboard
      changeTitle('New Dashboard - Flow.BI')
      // Reset report state for new dashboard
      setReport(emptyReport)
      reportRef.current = emptyReport
    }

    const originalTitle = document.title

    return () => {
      changeTitle(originalTitle)
    }
  }, [params.id])

  // Handle Window Resize
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const closeDialog = () => {
    setSaveAsDialogIsOpen(false)
    setSaveDialogIsOpen(false)
    setReport(emptyReport)
    reportRef.current = emptyReport
  }

  const changeTitle = (name: string) => {
    // change title to current report name
    document.title = name + ' - Flow.BI'
  }

  const sendUpdateRequest = async (reportId: string, updatedReport: Report) => {
    const { data } = await request(`/reports/${reportId}`, {
      method: 'put',
      jsonBody: updatedReport,
      queryParameters: {},
    })

    // data[0] = number of affected rows
    // data[1] = array of updated records
    return data[1][0] // Return the first updated record
  }

  const updateReport = async (reportId: string) => {
    console.log('updating report: ', reportId)
    const args = argsRef.current
    console.log('args: ', args)
    if (args) {
      const templateString: string = args.report.saveToJsonString()
      const templateObject: object = JSON.parse(templateString)

      setReport(prevReport => {
        const updatedReport = { ...prevReport, template: templateObject }
        sendUpdateRequest(reportId, updatedReport) // call api
        reportRef.current = updatedReport
        return updatedReport
      })

      // const data = await sendUpdateRequest(reportId, reportRef.current!)
      changeTitle(report.name)
      closeDialog()
      // return data
    }
  }

  const handleOverwrite = async (reportId: string) => {
    closeDialog()
    updateReport(reportId)
    updateURL(report)
  }

  // Save Report when SAVE button is pressed
  const handleSaveReport = async () => {
    const args = argsRef.current
    if (args) {
      const trimmedName = report.name.trim()
      args.report.reportName = trimmedName
      args.report.reportAlias = trimmedName

      const templateString: string = args.report.saveToJsonString()
      const templateObject: object = JSON.parse(templateString)

      setReport(prevReport => {
        const updatedReport = { ...prevReport, name: trimmedName, template: templateObject }
        reportRef.current = updatedReport
        return updatedReport
      })

      const currentReport = reportRef.current

      if (currentReport) {
        const { data, response } = await request(
          '/reports',
          {
            method: 'post',
            jsonBody: currentReport,
            queryParameters: {},
          },
          true
        )

        if (response.status === 409) {
          // setIsConflictError({ reportAleadyExist: true, id: data.id })
          // If report exist update the report object with the conflicting id
          setReport(report => {
            const updatedReport = { ...report, id: data.id }
            reportRef.current = updatedReport
            return updatedReport
          })
        } else {
          params.updateSidebar()

          setReport(prevReport => {
            const updatedReport = { ...prevReport, id: data.id }
            reportRef.current = updatedReport
            return updatedReport
          })

          setSaveDialogIsOpen(false)
          updateURL(data)
          closeDialog()
        }
      }

      changeTitle(report.name)
    }
  }

  // handle saveAs report when SAVE button in pressed
  const handleSaveAsReport = async () => {
    const args = argsRef.current
    if (args) {
      const trimmedName = report.name.trim()
      args.report.reportName = trimmedName
      args.report.reportAlias = trimmedName

      const templateString: string = args.report.saveToJsonString()
      const templateObject: object = JSON.parse(templateString)

      setReport(prevReport => {
        const updatedReport = { ...prevReport, name: trimmedName, template: templateObject }
        reportRef.current = updatedReport
        return updatedReport
      })

      const currentReport = reportRef.current

      if (currentReport) {
        const { data } = await request('/reports', {
          method: 'post',
          jsonBody: currentReport,
          queryParameters: {},
        })

        params.updateSidebar()

        setReport(prevReport => {
          const updatedReport = { ...prevReport, id: data.id }
          reportRef.current = updatedReport
          return updatedReport
        })

        setSaveAsDialogIsOpen(false)
        updateURL(data)
      }

      changeTitle(report.name + ' - Flow.BI')
      closeDialog()
    }
  }

  // // Load languages for Stimulsoft
  // useEffect(() => {
  //   try {
  //     for (const lang of languages) {
  //       Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile(
  //         lang.path,
  //         true,
  //         lang.language
  //       )
  //     }

  //     // Set preferred language of the user
  //     Stimulsoft.Base.Localization.StiLocalization.cultureName = user.preferred_language || 'en'
  //   } catch (error) {
  //     console.error('Error loading localization file: ', error)
  //   }
  // }, [])

  useEffect(() => {
    const run = async () => {
      // Stimulsoft activation
      Stimulsoft.Base.StiLicense.key = StimulsoftLicenseKey

      // Initialize Stimulsoft options and designer
      const options = new StiDesignerOptions()

      options.height = `${height - 100}px` // Compensate footer by reducing 100px
      options.appearance.allowChangeWindowTitle = false
      options.appearance.showSaveDialog = false
      options.toolbar.showFileMenuSaveAs = true
      options.toolbar.showFileMenuOpen = false
      options.toolbar.showFileMenuClose = false

      // Prevent user from adding new data source
      const dataSourcesViewPermission = Stimulsoft.Designer.StiDesignerPermissions.View

      options.dictionary.showAdaptersInNewConnectionForm = false
      options.dictionary.dataRelationsPermissions = dataSourcesViewPermission
      options.dictionary.dataConnectionsPermissions = dataSourcesViewPermission
      // options.dictionary.dataSourcesPermissions = Stimulsoft.Designer.StiDesignerPermissions.View // Prevent addition and creation of the data sources

      const designer = new StiDesigner(options, 'StiDesigner', false)

      // When save icon is pressed
      designer.onSaveReport = args => {
        argsRef.current = args
        // if (reportRef.current && reportRef.current.id) {
        if (params.id !== 'new' && params.id) {
          updateReport(params.id)
        } else {
          setSaveDialogIsOpen(true)
        }
      }

      // When save as is pressed
      designer.onSaveAsReport = args => {
        args.preventDefault = true
        setSaveAsDialogIsOpen(true)
        argsRef.current = args
      }

      // Configure Stimulsoft WebServer options
      StiOptions.WebServer.encryptData = false
      StiOptions.WebServer.checkDataAdaptersVersion = false
      StiOptions.WebServer.url = `${reportsUrl}:9615`

      try {
        const newReport = Stimulsoft.Report.StiReport.createNewDashboard()
        newReport.retrieveOnlyUsedData = true

        // Load a specific report
        if (params.id !== 'new') {
          const { data } = await request(`/reports/${params.id}`, {
            method: 'get',
            queryParameters: {},
          })
          changeTitle(data.name + ' - Flow.BI')
          setReport(data)
          setReceivedReport(true)
          reportRef.current = report
          const template = data.template

          newReport.load(template)
        }

        // Set client variables
        const variables = getVariables(Stimulsoft, reportRef, client, instance, user)

        variables.forEach(({ name, description, type, value }) => {
          const variable = new Stimulsoft.Report.Dictionary.StiVariable()
          variable.name = name
          variable.description = description
          variable.value = value ? value : ''
          variable.type = type
          variable.readOnly = true
          variable.allowUseAsSqlParameter = true
          newReport.dictionary.variables.add(variable)
        })

        // Clear all existing database and datasources
        newReport.dictionary.databases.clear()
        newReport.dictionary.dataSources.clear()

        // Pre-register a Postgres connection
        // Instead of adding a connection string as third argument. We are providing the database_user
        // The database adapter will need this value to SET ROLE and the correct connection string will be
        // replaced by the adapter. So this approach hides the connection string (for security purpose) from
        // the web-browser and allow us to send database user for switching Postgres role.
        const database = new Stimulsoft.Report.Dictionary.StiPostgreSQLDatabase(
          'repo',
          'repo',
          user.database_user // this will be replaced with a correct connection string at the data adapter
        )
        newReport.dictionary.databases.add(database)

        // Prevent user from editing existing database
        newReport.dictionary.restrictions.add(
          'repo',
          Stimulsoft.Report.Dictionary.StiDataType.Database,
          Stimulsoft.Report.Dictionary.StiRestrictionTypes.DenyEdit
        )

        const dataSourcesFromTemplate: Record<string, DataSource> =
          report?.template?.Dictionary?.DataSources

        if (dataSourcesFromTemplate) {
          // Iterate over the DataSources object
          Object.entries(dataSourcesFromTemplate).forEach(([key, dataSource]) => {
            const dataSourceFromTemplate = new Stimulsoft.Report.Dictionary.StiPostgreSQLSource(
              dataSource.NameInSource,
              dataSource.Name,
              dataSource.Alias,
              dataSource.SqlCommand,
              true,
              false,
              dataSource.CommandTimeout
            )

            dataSourceRef.current.push(dataSource.Name)

            // Check if the dataSource has a Columns object
            if (typeof dataSource === 'object' && dataSource !== null && 'Columns' in dataSource) {
              // Iterate over the Columns object
              Object.entries(dataSource.Columns as { [key: string]: any }).forEach(
                ([columnKey, columnValue]) => {
                  dataSourceFromTemplate.columns.add(
                    new Stimulsoft.Report.Dictionary.StiDataColumn(
                      columnValue.NameInSource,
                      columnValue.Name,
                      columnValue.Alias,
                      columnValue.Type
                    )
                  )
                }
              )
            }

            newReport.dictionary.dataSources.add(dataSourceFromTemplate)
          })
        }

        designer.report = newReport
      } catch (error) {
        console.error('Error loading report: ', error)
      }

      designer.renderHtml('renderDesigner')

      setLoading(false)
    }
    run()
  }, [receivedReport, height, params.id])

  return (
    <>
      {/* Dialog - save dashboard */}
      <Dialog
        onClick={() => {}} // prevents default behaviour of closing Dialog when clicked on backdrop
        open={saveDialogIsOpen}
        onClose={closeDialog}
        onKeyDown={e => handleEnterKeyDown(e, handleSaveReport)}
        sx={{ zIndex: 2147483647 }} // set z-index to maximum value for dialog to appear on top of the designer in fullscreen
      >
        <DialogTitle>Save Dashboard</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '3em' }}>
            Enter details to save the file
          </DialogContentText>
          <FormContent>
            <Label htmlFor="name">{t('name')}</Label>
            <Field>
              <TextField
                autoFocus
                id="name"
                type="text"
                hiddenLabel
                value={report.name}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setReport({ ...report, name: e.target.value })}
              />
            </Field>
            <Label htmlFor="group">{t('Folder')}</Label>
            <Field>
              <TextField
                autoFocus
                id="group"
                type="text"
                hiddenLabel
                value={report.group}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setReport({ ...report, group: e.target.value })}
                onBlur={e =>
                  setReport({ ...report, group: sanitizeAndFormatGroup(e.target.value) })
                }
              />
            </Field>
          </FormContent>
          {reportRef?.current?.id && (
            <DialogContentText color="error">
              This name is already taken. Do you want to overwrite the existing file?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant="outlined" onClick={closeDialog}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveReport}>
              Save
            </Button>
            {reportRef?.current?.id && (
              <Button
                variant="contained"
                color="error"
                onClick={() => reportRef?.current?.id && handleOverwrite(reportRef.current.id)}>
                Overwrite
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>

      {/* Dialog - saveAs dashboard */}
      <Dialog
        onClick={() => {}} // prevents default behaviour of closing Dialog when clicked on backdrop
        open={saveAsDialogIsOpen}
        onClose={closeDialog}
        onKeyDown={e => handleEnterKeyDown(e, handleSaveAsReport)}
        sx={{ zIndex: 2147483647 }} // set z-index to maximum value for dialog to appear on top of the designer in fullscreen
      >
        <DialogTitle>Save As Report</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '3em' }}>
            Enter details to save the file
          </DialogContentText>
          <FormContent>
            <Label htmlFor="name">{t('name')}</Label>
            <Field>
              <TextField
                autoFocus
                id="name"
                type="text"
                hiddenLabel
                value={report.name}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setReport({ ...report, name: e.target.value })}
              />
            </Field>
            <Label htmlFor="group">{t('Folder')}</Label>
            <Field>
              <TextField
                autoFocus
                id="group"
                type="text"
                hiddenLabel
                value={report.group}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setReport({ ...report, group: e.target.value })}
                onBlur={e =>
                  setReport({ ...report, group: sanitizeAndFormatGroup(e.target.value) })
                }
              />
            </Field>
          </FormContent>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant="outlined" onClick={closeDialog}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveAsReport}>
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {loading && (
        <div
          style={{
            display: 'flex',
            color: 'white',
            width: '100%',
            backgroundColor: '#555',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            height: '100%',
          }}>
          <DashboardOutlinedIcon /> &nbsp; Dashboard is Loading ...
        </div>
      )}
      {/* Load Fonts */}
      <FontLoader Stimulsoft={Stimulsoft} />
      {/* Load Designer */}
      <div
        id="renderDesigner"
        style={{
          width: loading ? '0px' : '100%',
          overflow: 'hidden',
        }}></div>
    </>
  )
}

// Create a sample text
// if (params.id === 'new') {
//   var sampleText = new Stimulsoft.Report.Components.StiText()
//   sampleText.clientRectangle = new Stimulsoft.System.Drawing.Rectangle(1, 1, 5, 2)
//   sampleText.text = 'Sample Text'
//   sampleText.font.size = 21
//   sampleText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All
//   const page = newReport.pages.getByIndex(0)
//   page.components.add(sampleText)
// }

// Pre-register entities
// const entities = [
//   { table_schema: 'info_billing', table_name: 'billing_f' },
//   { table_schema: 'info_overview', table_name: 'target_perspective_f' },
//   { table_schema: 'info_overview', table_name: 'process_perspective_f' },
//   { table_schema: 'info_overview', table_name: 'source_perspective_f' },
//   { table_schema: 'info_overview', table_name: 'issues_perspective_f' },
// { table_schema: 'info_overview', table_name: 'datasources_f' },
// { table_schema: 'info_overview', table_name: 'entities_f' },
// { table_schema: 'info_overview', table_name: 'issues_f' },
// { table_schema: 'info_overview', table_name: 'ldts_jobs_f' },
// { table_schema: 'info_overview', table_name: 'sdts_jobs_f' },
// { table_schema: 'info_overview', table_name: 'source_columns_f' },
// { table_schema: 'info_overview', table_name: 'source_entities_f' },
// { table_schema: 'info_overview', table_name: 'source_records_f' },
// ]

// entities.forEach(async ({ table_schema, table_name }) => {
//   const entityName = `${table_schema}.${table_name}`
//   const entityQuery = `select * from ${entityName} where gsr_client = @Client and gsr_inst = @Instance order by gsr_sort;`

//   if (!dataSourceRef.current.includes(entityName)) {
//     const dataSource = new Stimulsoft.Report.Dictionary.StiPostgreSQLSource(
//       database.name,
//       entityName,
//       database.name,
//       entityQuery,
//       true,
//       false,
//       300 // seconds
//     )

//     newReport.dictionary.dataSources.add(dataSource)
//   }
// })
