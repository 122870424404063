import { Routes, Route, useParams, Navigate } from 'react-router-dom'
import { HelpBox } from './components/HelpBox'
import { FullPageDataTableContainer } from './components/DataTable'
import Skeleton from '@mui/material/Skeleton'
import { FormTabs } from './components/FormTabs'
import { Form, FormContent } from './components/Form'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { useTranslation } from './services/i18n'
import { Concepts } from './Concepts'
import { Rules } from './Rules'

export const Influence = () => (
  <Routes>
    <Route path="/" element={<Navigate to="concepts" replace />} />
    <Route path=":tabId" element={<Influences />} />
  </Routes>
)

const Influences = () => {
  const { t } = useTranslation()
  const { tabId: currentTab } = useParams()

  return (
    <FullPageDataTableContainer>
      <HelpBox title={<Skeleton animation={false} width={500} />}>
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} width={600} />
      </HelpBox>
      <Form>
        <FormTabs
          tabs={[
            { id: 'concepts', label: t('concepts'), icon: <TextSnippetIcon /> },
            { id: 'security-rules', label: t('security-rules'), icon: <TextSnippetIcon /> },
            { id: 'privacy-rules', label: t('privacy-rules'), icon: <TextSnippetIcon /> },
            { id: 'change-rules', label: t('change-rules'), icon: <TextSnippetIcon /> },
          ]}
        />

        <FormContent hidden={currentTab !== 'concepts'} fullHeight>
          <Concepts />
        </FormContent>

        <FormContent hidden={currentTab !== 'security-rules'} fullHeight>
          <Rules type={'security'} />
        </FormContent>

        <FormContent hidden={currentTab !== 'privacy-rules'} fullHeight>
          <Rules type={'privacy'} />
        </FormContent>

        <FormContent hidden={currentTab !== 'change-rules'} fullHeight>
          <Rules type={'change'} />
        </FormContent>
      </Form>
    </FullPageDataTableContainer>
  )
}
