import React, { useEffect, useMemo, useState } from 'react'
import {
  Stimulsoft,
  StiViewer,
  StiViewerOptions,
  StiOptions,
} from 'stimulsoft-dashboards-js/Scripts/stimulsoft.viewer'
import { request } from '../state/api'
import { StimulsoftLicenseKey } from './ReportDashboard'
// import { loadFonts } from './ReportDesigner'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import { Report } from './ReportDashboard'
import FontLoader from './FontLoader'

import { useConfig } from '../state/config'

type Params = {
  id: string | undefined
}

export const ViewerDashboard = (params: Params) => {
  const [reportData, setReportData] = useState<Report | null>(null)
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(true)

  const reportsUrl = useConfig(store => store.reportsUrl)

  const loadReport = async () => {
    const { data } = await request(`/reports/${params.id}`, {
      method: 'get',
      queryParameters: {},
    })

    setReportData(data)
    document.title = data.name + ' - Flow.BI'
  }

  // Handle Window Resize
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (params.id !== 'new') {
      loadReport()
      //   loadFonts()
    }
    const originalTitle = document.title

    return () => {
      document.title = originalTitle
    }
  }, [params.id])

  useEffect(() => {
    // Stimulsoft activation
    Stimulsoft.Base.StiLicense.key = StimulsoftLicenseKey

    if (reportData) {
      const template = reportData.template

      if (!Object.keys(template).length) console.error("Template can't be empty")

      const report = new Stimulsoft.Report.StiReport()
      report.retrieveOnlyUsedData = true

      report.onBeginRender = () => {
        setLoading(true)
      }
      report.onEndRender = () => {
        setLoading(false)
      }
      report.load(template)

      const options = new StiViewerOptions()
      // Add option here
      options.toolbar.visible = true
      options.toolbar.showOpenButton = false
      options.appearance.scrollbarsMode = true
      options.appearance.fullScreenMode = false
      options.appearance.allowMobileMode = false
      options.appearance.backgroundColor = Stimulsoft.System.Drawing.Color.fromName('gray') // Use Stimulsoft color
      options.height = `${height - 100}px`
      // options.width = `${width}px`

      // Configure Stimulsoft WebServer options
      StiOptions.WebServer.encryptData = false
      StiOptions.WebServer.checkDataAdaptersVersion = false
      StiOptions.WebServer.url = `${reportsUrl}:9615`

      const viewer = new StiViewer(options, 'StiViewer', false)
      viewer.report = report
      viewer.renderHtml('renderViewer')
    }
  }, [reportData, height, width])

  if (params.id === 'new') {
    return (
      <div
        style={{
          display: 'flex',
          color: 'white',
          width: '100%',
          backgroundColor: '#555',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
        }}>
        Provide a valid &nbsp; <SummarizeOutlinedIcon /> &nbsp; Report or &nbsp;
        <DashboardOutlinedIcon /> &nbsp; Dashboard ID
      </div>
    )
  }
  return (
    <>
      {/* {loading && (
        <div
          style={{
            display: 'flex',
            color: 'white',
            width: '100%',
            backgroundColor: '#555',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            height: '100%',
          }}>
          <DashboardOutlinedIcon /> &nbsp; Dashboard is Loading ...
        </div>
      )} */}
      <FontLoader Stimulsoft={Stimulsoft} />
      <div id="renderViewer" style={{ width: '100%' }}></div>
    </>
  )
}
