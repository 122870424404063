import React, { useState, useEffect, useCallback } from 'react'
import { Routes, Route, useParams, useNavigate, Link, Navigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import KeyIcon from '@mui/icons-material/Key'
import Alert from '@mui/material/Alert'

import { Breadcrumbs } from './settings/Breadcrumbs'
import { useTranslation } from './services/i18n'
import { useSnackbar } from './components/Snackbars'
import { DeploymentTarget } from '../../api/app/deploymentTargets'
import { request } from './state/api'
import { RoleAutoSuggestList } from './components/RoleAutoSuggestList'
import { ResourceRole } from '../../api/app/roles'
import { useInstance } from './state/auth/currentInstance'
import { FormButtons } from './components/FormButtons'
import { FormTabs } from './components/FormTabs'
import { Form, FormContent, Label, Field } from './components/Form'
import { FullPageDataTableContainer } from './components/DataTable'

// Some pipeline ids and pipeline properties come with wonderful constructs based on colons and dots. As it happens,
// i18next uses these for namespaces and nested objects by default so this would clash. Don't want to change the
// defaults of i18next here, so we simply replace those characters with dashes.

export const DeploymentTargetsForm = () => (
  <Routes>
    <Route path="/" element={<Navigate to="pipeline-config" replace />} />
    <Route path=":tabId" element={<Content />} />
  </Routes>
)

export const Content = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const instanceId = useInstance(instance => instance.gsr_inst)
  const { connectedSystemId: recordId, tabId: currentTab } = useParams()
  const { showSnackbar } = useSnackbar()
  const [, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [label, setLabel] = useState<string>('')
  const [roles, setRoles] = useState<ResourceRole[]>([])
  const [pipelineId, setPipelineId] = useState<string>('')
  const [config, setConfig] = useState<string>('')

  const url = '/deployment-targets'

  const load = useCallback(async () => {
    setIsLoading(true)
    const response = (await request(url + '/' + recordId)).data
    let connectedSystem = response as DeploymentTarget

    setLabel(connectedSystem.name || '')
    // @ts-ignore
    setPipelineId(connectedSystem.pipeline || '')
    setConfig(JSON.stringify(connectedSystem.config) || '')
    setIsLoading(false)
    setRoles(connectedSystem.assignedRoles || [])
  }, [recordId, url])

  useEffect(() => {
    recordId && load()
  }, [recordId, load])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsSaving(true)

    const jsonBody = {
      label,
      roles,
      config,
      pipeline: pipelineId,
    }

    try {
      if (!recordId) {
        await request(`/instances/${instanceId}${url}`, { method: 'post', jsonBody })
      } else {
        await request(`${url}/${recordId}`, { method: 'put', jsonBody })
      }

      showSnackbar({ message: t('save-success'), severity: 'success' })
      navigate('../../')
    } catch (e) {
      showSnackbar({ message: t('save-error'), severity: 'error' })
    }

    setIsSaving(false)

    return false
  }

  let breadcrumbTitle = t('navigation.update-resource', { label })

  if (!recordId) {
    breadcrumbTitle = t('add-new-target')
  }

  return (
    <FullPageDataTableContainer>
      <Breadcrumbs>
        <Link to="../../">
          <Typography color="text.secondary">{t('navigation.targets')}</Typography>
        </Link>
        <Typography color="text.primary">{breadcrumbTitle}</Typography>
      </Breadcrumbs>
      <Form onSubmit={e => handleSubmit(e)}>
        <Typography
          value={label || breadcrumbTitle}
          component="input"
          sx={{ fontSize: '2rem', marginBottom: 1, border: 'none', '&:focus': { outline: 'none' } }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
          color="text.primary"
        />

        <FormTabs
          tabs={[
            { id: 'pipeline-config', label: t('pipeline-config'), icon: <TextSnippetIcon /> },
            { id: 'acl', label: t('acl'), icon: <KeyIcon /> },
          ]}
        />

        <FormContent hidden={currentTab !== 'pipeline-config'}>
          <Label htmlFor="name">{t('name')}</Label>
          <Field>
            <TextField
              autoFocus
              id="name"
              type="text"
              hiddenLabel
              value={label}
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              onChange={e => setLabel(e.target.value)}
            />
          </Field>
          <PipelineSelector pipelineId={pipelineId} setPipelineId={setPipelineId} />

          <ConfigOptions pipelineId={pipelineId} config={config} setConfig={setConfig} />
        </FormContent>

        <FormContent hidden={currentTab !== 'acl'}>
          <Field md={12}>
            <RoleAutoSuggestList roles={roles} setRoles={setRoles} />
          </Field>
        </FormContent>

        {currentTab === 'acl' && (
          <>
            <Alert severity="info">{t('permissions-hint-delay')}</Alert>
          </>
        )}

        <FormButtons isSaving={isSaving} />
      </Form>
    </FullPageDataTableContainer>
  )
}

const PipelineSelector = (props: {
  pipelineId?: string
  setPipelineId: (pipelineId: string) => void
}) => {
  const { t } = useTranslation()

  let field = (
    <TextField
      select
      hiddenLabel
      margin="dense"
      variant="standard"
      value={props.pipelineId}
      onChange={({ target }) => props.setPipelineId(target.value)}>
      <MenuItem value="datavault4dbt">{t('datavault4dbt')}</MenuItem>
      <MenuItem value="datavaultbuilder">{t('datavaultbuilder')}</MenuItem>
      <MenuItem value="dsharp">{t('dsharp')}</MenuItem>
      <MenuItem value="iris">{t('iris')}</MenuItem>
      {/* <MenuItem value="wherescape">{t('wherescape')}</MenuItem>
      <MenuItem value="vaultspeed">{t('vaultspeed')}</MenuItem>
      <MenuItem value="coalesce">{t('coalesce')}</MenuItem> */}
    </TextField>
  )

  return (
    <React.Fragment>
      <Label htmlFor="pipeline">{t('pipeline-type')}</Label>
      <Field>{field}</Field>
    </React.Fragment>
  )
}

const ConfigOptions = (props: {
  pipelineId?: string
  config: string
  setConfig: (config: string) => void
}) => {
  const { t } = useTranslation()

  if (!props.pipelineId) {
    return <React.Fragment>{t('select-pipeline-first')}.</React.Fragment>
  }

  let textarea = (
    <TextField
      placeholder="json"
      multiline
      fullWidth
      minRows={8}
      maxRows={20}
      value={props.config}
      onChange={({ target }) => props.setConfig(target.value)}
    />
  )

  return (
    <React.Fragment>
      <Label htmlFor="pipeline-config">{t('pipeline-config')}</Label>
      <Field>{textarea}</Field>
    </React.Fragment>
  )
}
