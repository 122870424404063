import React, { useState, useEffect, useCallback } from 'react'
import { Routes, Route, useParams, useNavigate, Link, Navigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import KeyIcon from '@mui/icons-material/Key'
import MenuItem from '@mui/material/MenuItem'
import Alert from '@mui/material/Alert'

import { Breadcrumbs } from './Breadcrumbs'
import { useTranslation } from '../services/i18n'
import { useSnackbar } from '../components/Snackbars'
import { Instance } from '../../../api/app/instances'
import { useClient, useInstancesOfClient } from '../state/auth/currentClient'
import { request } from '../state/api'
import { RoleAutoSuggestList } from '../components/RoleAutoSuggestList'
import { ResourceRole } from '../../../api/app/roles'
import { FormButtons } from '../components/FormButtons'
import { FormTabs } from '../components/FormTabs'
import { Form, FormContent, Label, Field } from '../components/Form'
import { FullPageDataTableContainer } from '../components/DataTable'

// https://github.com/microsoft/TypeScript/issues/49231
declare namespace Intl {
  export function supportedValuesOf(
    input: 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit'
  ): string[]
}
// @ts-ignore
const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const InstancesForm = () => (
  <Routes>
    <Route path="/" element={<Navigate to="general" replace />} />
    <Route path=":tabId" element={<Content />} />
  </Routes>
)

export const Content = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const clientId = useClient(client => client.gsr_client)
  const { gsr_inst: recordId, tabId: currentTab } = useParams()
  const { showSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const refreshInstances = useInstancesOfClient(({ refresh }) => refresh)

  const [name, setName] = useState<string>('')
  const [timeZone, setTimeZone] = useState<string>(DEFAULT_TIMEZONE)
  const [roles, setRoles] = useState<ResourceRole[]>([])

  const load = useCallback(async () => {
    setIsLoading(true)
    const instance = (await request('/instances/' + recordId)).data as Instance
    setName(instance.name || '')
    setTimeZone(instance.time_zone || DEFAULT_TIMEZONE)
    setIsLoading(false)
    setRoles(instance.assignedRoles || [])
  }, [recordId])

  useEffect(() => {
    recordId && load()
  }, [recordId, load])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsSaving(true)

    const jsonBody = { name, time_zone: timeZone, roles }

    try {
      if (!recordId) {
        await request(`/clients/${clientId}/instances`, { method: 'post', jsonBody })
      } else {
        await request(`/instances/${recordId}`, { method: 'put', jsonBody })
      }

      // Refresh the global instances store so that changes made to the list of instances are immediately
      // reflected in the UI wherever needed. I.e. this makes sure newly created instances are pushed
      // into the instance selector without requiring a reload.
      refreshInstances()

      showSnackbar({ message: t('save-success'), severity: 'success' })
      navigate('../../')
    } catch (e) {
      showSnackbar({ message: t('save-error'), severity: 'error' })
    }

    setIsSaving(false)

    return false
  }

  return (
    <FullPageDataTableContainer>
      <Breadcrumbs>
        <Link to="../../">
          <Typography color="text.secondary">{t('navigation.instances')}</Typography>
        </Link>
        <Typography color="text.primary">
          {recordId ? t('navigation.update-resource', { name }) : t('add-new-instance')}
        </Typography>
      </Breadcrumbs>
      <Form onSubmit={e => handleSubmit(e)}>
        <FormTabs
          tabs={[
            { id: 'general', label: t('general-information'), icon: <TextSnippetIcon /> },
            { id: 'acl', label: t('acl'), icon: <KeyIcon /> },
          ]}
        />

        <FormContent hidden={currentTab !== 'general'}>
          <Label htmlFor="name">{t('name')}</Label>
          <Field>
            <TextField
              autoFocus
              fullWidth
              id="name"
              type="text"
              hiddenLabel
              value={name}
              margin="dense"
              variant="outlined"
              size="small"
              onChange={e => setName(e.target.value)}
            />
          </Field>

          <Label htmlFor="time_zone">{t('time-zone')}</Label>
          <Field>
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              hiddenLabel
              value={timeZone}
              onChange={({ target }) => setTimeZone(target.value)}
              select>
              {Intl.supportedValuesOf('timeZone').map(zone => (
                <MenuItem value={zone}>{zone}</MenuItem>
              ))}
            </TextField>
          </Field>
        </FormContent>

        <FormContent hidden={currentTab !== 'acl'}>
          <Field md={12}>
            <RoleAutoSuggestList roles={roles} setRoles={setRoles} />
          </Field>
        </FormContent>

        {currentTab === 'acl' && (
          <>
            <Alert severity="info">{t('permissions-hint-delay')}</Alert>
          </>
        )}

        <FormButtons isSaving={isSaving} />
      </Form>
    </FullPageDataTableContainer>
  )
}
