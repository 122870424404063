import React from 'react'
import ReactDOM from 'react-dom/client'
import { setLicenseKey } from '@grapecity/wijmo'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { StyledEngineProvider } from '@mui/material/styles'
import { Layout } from './components/layout/Layout'
import { Login } from './Login'
import { ThemeProvider } from './components/Theme'
import { useAuth } from './state/auth'
import { DeploymentTargets } from './DeploymentTargets'
import { InformationFlows } from './InformationFlows'
import { Dashboard } from './Dashboard'
import { Settings } from './Settings'
import { DataModel } from './DataModel'
import { SnackBarProvider } from './components/Snackbars'
import { Sources } from './Sources'
import { POCChecker } from './POCChecker'
import { Influence } from './Influences'
import { InstanceOptions } from './InstanceOptions'
import { ApiErrorNotification } from './components/ApiErrorNotification'
import { InstanceStatus } from './InstanceStatus'
import { Buffer } from 'buffer'
import { Overview } from './Overview'
import { ReportDashboard } from './Report/ReportDashboard'
import './base.css'
import './services/i18n'

// To get rid of Wijmo banner
setLicenseKey(
  'app.test.flbi.net|app.uat.flbi.net|app.prod.flbi.net|app.flow.bi|app.dev.flbi.net,364459815399839#B0x2SXbpjInxmZiwiIyYHNyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZ5Y6QXlmWxsEezJ6TVRHcZNzawMDctBzLwo5V8w4dQxGbTB7SMdjR62CWr3iVEx6NalDUoJDVWlWMtt4YSxUdFp5ciJUYxkXe7UHe5FXSD3Sd8hmatlGWmlnbPhDeoJGeqlUQ4c5Zqhja5skRkp5auFmMrtURKdkUUZHaBNEWM5URNpnVWNjN6IXMXB5L5p6LllFVkJURB5kd59UVjxkd7YUMtJWTSdGRwZjMJZ5b8xGSLNEOr9Wd6kVZQJmc5MnWSNzVvYGc5lnSR3EaERkaBhmMjdVaZ3yKyEDOUZWY6RmQiVDO9EEc6EEcDZTZxtkMwN4YMhXTBJnQ6wGR5YVOW5WMZpXRsJFOGFESkNXQplUR6kTMwR7TBdFWVpHR44kdW5mdYNDbaJXT4IGO5FTVvlUbSdGSkFTYKxEeLlzSltSUJdzTOdDeoBnYkVGb9knZNF7YtpmI0IyUiwiI6IUREF4QzYjI0ICSiwSOxMzNxQDO6QTM0IicfJye=#Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIzMzN4YDMgAzM9ADNyAjMiojI4J7QiwiI4VmbukmYsZmL6VGZuAHchxSai9ydvxmZuAHchxCdl9mLpJGbm9CZvJHcuAHchxCdl9mLpJGbm9CdhVnLwBXYsQXZu9SaixmZuQ7clRnLwBXYiojIz5GRiwiIIJWbHBSSC9ydvxmRiojIh94QiwiI9MDO9kzM5EDO9UDN4YzMiojIKlKI'
)

declare global {
  interface Window {
    Buffer: any
  }
  // Extend the HTMLElement interface to include the custom property __root
  interface HTMLElement {
    __root?: ReactDOM.Root
  }
}

export const App = () => {
  window.Buffer = Buffer
  const isAuthenticated = useAuth(state => state.isLoggedIn)

  return (
    <StyledEngineProvider injectFirst>
      {/** @ts-ignore */}
      <ThemeProvider>
        <React.Suspense fallback={<div>Loading...</div>}>
          <SnackBarProvider>
            <Router>
              <ApiErrorNotification />
              <div className="App">
                {!isAuthenticated && <Login />}
                {isAuthenticated && <Body />}
              </div>
            </Router>
          </SnackBarProvider>
        </React.Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const Body = () => {
  return (
    <Layout>
      <Routes>
        <Route path="wepocyet/*" element={<POCChecker />} />
        <Route path="status/*" element={<InstanceStatus />} />
        <Route path="sources/*" element={<Sources />} />
        <Route path="targets/*" element={<DeploymentTargets />} />
        <Route path="model/*" element={<DataModel />} />
        <Route path="influence/*" element={<Influence />} />
        <Route path="information-flows/*" element={<InformationFlows />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="instance-options/*" element={<InstanceOptions />} />
        <Route path="reports/:section" element={<ReportDashboard />} />
        <Route path="reports/*" element={<ReportDashboard />} />
        <Route path="/" element={<Overview />} />
        <Route path="*" element={<Overview />} />
      </Routes>
    </Layout>
  )
}

const container = document.getElementById('root') as HTMLElement
let root: ReactDOM.Root

if (!container.__root) {
  root = ReactDOM.createRoot(container)
  container.__root = root
} else {
  root = container.__root
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
